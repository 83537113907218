.card {
    padding-bottom: 4px;
}

.card-body {
    color: #00303b;
}

.card-header {

}

.card-time {
    font-size: 150%;
}

.card-text {

}

button, input[type=submit] {
    background: transparent;
    border: 2px solid #ff7777;
    color: #ff7777;
    font-size: 90%;
    width: auto;
}

button:hover, input[type=submit]:hover {
    cursor: pointer;
}

.card-button {
    margin-bottom: 12px;
    margin-right: 8px;
}

.card-button-plus {
    width: 28px;
    height: 28px;
}